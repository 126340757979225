import {
  BundlrStorageDriver,
  Metaplex,
  sol,
  toBigNumber,
  toMetaplexFileFromBrowser,
  UploadMetadataInput,
  UploadMetadataOutput,
} from '@metaplex-foundation/js';
import {
  LAMPORTS_PER_SOL,
  Connection,
  clusterApiUrl,
  Keypair,
  PublicKey,
  Transaction,
  SystemProgram,
  sendAndConfirmTransaction,
} from '@solana/web3.js';
import { toast } from 'react-toastify';

export const getUserNFTs = async (metaplex: Metaplex) => {
  let myNFTs = await metaplex
    ?.nfts()
    .findAllByOwner({ owner: metaplex.identity().publicKey });
  console.log(myNFTs);
};

export const uploadImage = async (metaplex: Metaplex) => {};

export const createNFT = async (params: {
  metaplex: Metaplex;
  metadata: UploadMetadataInput;
}) => {
  try {
    const { uri } = await params.metaplex.nfts().uploadMetadata({
      name: `${params.metadata?.name}`,
      description: params.metadata?.description,
      symbol: params.metadata?.symbol,
      external_url: params.metadata?.external_url,
      attributes: params.metadata?.attributes,
      //@ts-ignore
      image: await toMetaplexFileFromBrowser(params.metadata.files[0]),
    });
    toast.success('Finalizing...');

    const { nft } = await params.metaplex.nfts().create({
      uri: uri,
      symbol: params.metadata?.symbol,
      name: params.metadata?.name!,
      sellerFeeBasisPoints: params.metadata.seller_fee_basis_points!, // Represents 5.00%.
      maxSupply: toBigNumber(0),
    });
    toast.success('NFT Created');

    console.log('uri =>', uri);
    console.log('nft =>', nft);
    console.log('Mint Address =>', nft.mint.address.toString());
  } catch (error) {
    throw error;
  }
};

export const fundMe = async (metaplex: Metaplex, connection: Connection) => {
  let airdropSignature = await connection.requestAirdrop(
    metaplex.identity().publicKey,
    LAMPORTS_PER_SOL
  );
  const latestBlockHash = await connection.getLatestBlockhash();
  let res = await connection.confirmTransaction({
    blockhash: latestBlockHash.blockhash,
    lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
    signature: airdropSignature,
  });
};

export const findAllByCreator = async (metaplex: Metaplex) =>
  metaplex.nfts().findAllByCreator({ creator: metaplex.identity().publicKey });

export const findAllByOwner = async (metaplex: Metaplex) =>
  metaplex.nfts().findAllByOwner({ owner: metaplex.identity().publicKey });

export const findByMint = async (metaplex: Metaplex, mintAddress: any) =>
  metaplex.nfts().findByMint({ mintAddress });

export const createMXAuctionHouse = async (
  metaplex: Metaplex,
  connection: Connection,
  sendTransaction: any
) => {
  //Create Auction House
  console.log('Creating AH.....');
  const { auctionHouse } = await metaplex.auctionHouse().create({
    sellerFeeBasisPoints: 0,
  });

  /**
   * Future case if Auction house was already made by the user
   * Derive AH address and Fee account address manually store them in the DB
   */

  console.log('Funding AH.....');
  //Fund Fee Account of Auction House
  await fundFeeAccount(
    metaplex,
    sendTransaction,
    connection,
    auctionHouse.feeAccountAddress
  ).catch((err) => {
    console.log('While funding fee account: ', err.message);
    throw new Error(err.message);
  });

  return {
    auction_house_address: auctionHouse.address.toString(),
    user_address: metaplex.identity().publicKey.toString(),
    auction_house_fee_account: auctionHouse.feeAccountAddress.toString(),
    seller_fee_point: '0',
  };
};

async function fundFeeAccount(
  metaplex: Metaplex,
  sendTransaction: any,
  connection: Connection,
  feeAccount: PublicKey
) {
  try {
    const tx = new Transaction().add(
      SystemProgram.transfer({
        toPubkey: feeAccount,
        fromPubkey: metaplex.identity().publicKey,
        lamports: LAMPORTS_PER_SOL / 10,
      })
    );

    const signature = await sendTransaction(tx, connection);
    let txRes = await connection.confirmTransaction(signature, 'processed');
    console.log(txRes);
  } catch (error) {
    throw error;
  }
}

export const createMXListing = async (
  metaplex: Metaplex,
  price: string,
  ahAddress: string,
  mint_address: string
) => {
  try {
    const ah = await metaplex
      .auctionHouse()
      .findByAddress({ address: new PublicKey(ahAddress) });

    const { listing, sellerTradeState } = await metaplex.auctionHouse().list({
      auctionHouse: ah,
      mintAccount: new PublicKey(mint_address),
      price: sol(Number(price)),
    });
    return {
      seller_trade_state: sellerTradeState.toBase58(),
    };
  } catch (error) {
    throw error;
  }
};

export const cancelMXListing = async (
  metaplex: Metaplex,
  ahAddress: string,
  seller_trade_state: string
) => {
  try {
    const ah = await metaplex
      .auctionHouse()
      .findByAddress({ address: new PublicKey(ahAddress) });

    const listing = await metaplex.auctionHouse().findListingByTradeState({
      tradeStateAddress: new PublicKey(seller_trade_state),
      auctionHouse: ah,
    });

    let cancelledListing = await metaplex.auctionHouse().cancelListing({
      auctionHouse: ah,
      listing: listing,
    });
  } catch (error) {
    throw error;
  }
};

export const createMXBid = async (
  metaplex: Metaplex,
  price: string,
  ahAddress: string,
  mint_address: string
) => {
  console.log({ price, ahAddress, mint_address });
  try {
    const ah = await metaplex
      .auctionHouse()
      .findByAddress({ address: new PublicKey(ahAddress) });

    const { buyerTradeState } = await metaplex.auctionHouse().bid({
      auctionHouse: ah,
      mintAccount: new PublicKey(mint_address),
      price: sol(Number(price)),
    });

    return {
      buyer_trade_state: buyerTradeState.toBase58(),
      user_address: metaplex.identity().publicKey.toString(),
      mint_address: mint_address,
      price: price,
    };
  } catch (error) {
    throw error;
  }
};

export const cancelMXbid = async (
  metaplex: Metaplex,
  buyer_trade_state: string,
  ahAddress: string
) => {
  try {
    const ah = await metaplex
      .auctionHouse()
      .findByAddress({ address: new PublicKey(ahAddress) });

    let bid = await metaplex.auctionHouse().findBidByTradeState({
      auctionHouse: ah,
      tradeStateAddress: new PublicKey(buyer_trade_state),
    });

    let canceledBid = await metaplex.auctionHouse().cancelBid({
      auctionHouse: ah,
      bid,
    });
    console.log('Cancelled bid sig:', canceledBid.response.signature);
  } catch (error) {
    throw error;
  }
};

export const executeSale = async (
  metaplex: Metaplex,
  buyer_trade_state: string,
  seller_trade_state: string,
  ahAddress: string,
  bid_price: string,
  mint_address: string,
  selling_price: string
) => {
  try {
    //Cancel listing

    if (selling_price !== bid_price) {
      await cancelMXListing(metaplex, ahAddress, seller_trade_state);
      //Create listing with Matching price
      let { seller_trade_state: new_seller_trade_state } =
        await createMXListing(metaplex, bid_price, ahAddress, mint_address);
      //Execute sale
      let mxRes = await executeMXSale(
        metaplex!,
        buyer_trade_state,
        new_seller_trade_state,
        ahAddress
      );
      return mxRes;
    } else {
      let mxRes = await executeMXSale(
        metaplex!,
        buyer_trade_state,
        seller_trade_state,
        ahAddress
      );
      return mxRes;
    }
  } catch (error) {
    throw error;
  }
};

export const executeMXSale = async (
  metaplex: Metaplex,
  buyer_trade_state: string,
  seller_trade_state: string,
  ahAddress: string
) => {
  try {
    const ah = await metaplex
      .auctionHouse()
      .findByAddress({ address: new PublicKey(ahAddress) });
    const listing = await metaplex.auctionHouse().findListingByTradeState({
      auctionHouse: ah,
      tradeStateAddress: new PublicKey(seller_trade_state),
    });

    console.log('---Fetching Bid----');
    const bid = await metaplex.auctionHouse().findBidByTradeState({
      auctionHouse: ah,
      tradeStateAddress: new PublicKey(buyer_trade_state),
    });

    console.log('---Executing Sale----');
    const purchase = await metaplex.auctionHouse().executeSale({
      auctionHouse: ah,
      listing: listing,
      bid: bid,
    });
    return {
      seller_trade_state: seller_trade_state,
      buyer_trade_state: buyer_trade_state,
      auction_house_address: ahAddress,
      seller_address: metaplex.identity().publicKey.toString(),
    };
  } catch (error) {
    throw error;
  }
};
