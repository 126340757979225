import { useEffect, useState } from 'react';
import Modal from '../../../_common/modal';
import OfferModal from './offerModal';
import styles from './prices.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import ListingModal from './listingModal';
import { useMetaplex } from '../../../../hooks/useMetaplex';
import { useListing } from './useListing';
import LoaderSpinner from '../../loaderSpinner/loaderSpinner';
import { useWallet } from '@solana/wallet-adapter-react';
import { useOffer } from './useOffer';

type Props = {
  showSale: boolean;
  name: string;
  nftItem: any;
  routedFromListings: boolean;
};

const Prices = (props: Props) => {
  const { wallet } = useWallet();

  const isAdmin = useSelector((state: RootState) => state.general.isAdmin);
  const [popupvisible, setpopupvisible] = useState(false);
  const [showListingModal, setShowListingModal] = useState(false);

  const toggleListingModal = () => setShowListingModal(!showListingModal);
  const toggleOfferModal = () => setpopupvisible(!popupvisible);

  const [onCreateListing, loading, listingData, cancelListing] = useListing(
    props.nftItem,
    wallet?.adapter.publicKey?.toBase58()!,
    toggleListingModal
  );

  const [offerLoading, onCreateOffer, onCancelOffer, offerExists] = useOffer(
    props.nftItem,
    toggleOfferModal
  );

  const solPrice = useSelector((state: RootState) => state.general.solPrice);

  const togglepopup = (e: any) => {
    e.preventDefault();
    setpopupvisible((preview) => !preview);
  };

  const RenderButton = () => {
    if (loading || offerLoading) return <LoaderSpinner />;
    if (Boolean(listingData?.listing) && isAdmin) {
      return (
        <button
          onClick={() =>
            cancelListing(listingData?.listing?.seller_trade_state)
          }
        >
          Cancel Listing
        </button>
      );
    } else if (Boolean(listingData?.listing) && !isAdmin) {
      if (offerExists) {
        return (
          <button onClick={onCancelOffer}>
            Cancel Offer <strong>({offerExists.price} SOL)</strong>
          </button>
        );
      } else {
        return <button onClick={toggleOfferModal}>Make Offer</button>;
      }
      //Check if user already have a offer
      // yes cancel offer
      // no make offer
    } else {
      return <button onClick={toggleListingModal}>Sell Now</button>;
    }
  };

  return (
    <>
      <Modal
        loading={offerLoading}
        visible={popupvisible}
        btn
        onClose={toggleOfferModal}
      >
        <OfferModal onPressOffer={onCreateOffer} />
      </Modal>
      <Modal
        loading={loading}
        visible={showListingModal}
        btn
        onClose={toggleListingModal}
      >
        <ListingModal onPressListing={onCreateListing} />
      </Modal>
      <div className={styles.headingWrapper}>
        <label>{props.name}</label>
        {/* <p>
            Owned By <span>Avrill15</span>
          </p> */}
      </div>
      <div className={styles.cardWrapper}>
        {/* <div className={styles.header}>Sale ends September 12,2022</div> */}
        {props.nftItem?.price && (
          <div className={styles.price}>
            <label>Listed Price</label>
            <p>
              <img
                src={'https://d3bfm8su4pz02o.cloudfront.net/icons/NFTIcon.svg'}
                alt=""
              />
              {props.nftItem?.price}
              <span>${props.nftItem?.price * Number(solPrice)}</span>
            </p>
          </div>
        )}

        <div className={styles.btnWrapper}>{<RenderButton />}</div>
      </div>
    </>
  );
};

export default Prices;
