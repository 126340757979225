import { gql } from '@apollo/client';

/**
 * Params
 *  "auctionHouse": {
    "auction_house_address": null,
    "user_address": null,
    "auction_house_fee_account": null,
    "seller_fee_point": null
  }
 */

export type TCreateAuctionHouse = {
  auction_house_address: string;
  user_address: string;
  auction_house_fee_account: string;
  seller_fee_point: string;
};

export type TSale = {
  seller_address: string;
  buyer_address: string;
  seller_trade_state: string;
  buyer_trade_state: string;
  mint_address: string;
  price: string;
  auction_house_address: string;
  createdAt: string;
};

export type TCreateSale = {
  seller_address: string;
  buyer_address: string;
  seller_trade_state: string;
  buyer_trade_state: string;
  mint_address: string;
  price: string;
  auction_house_address: string;
};

const CREATE_AUCTION_HOUSE = gql`
  mutation Mutation($auctionHouse: CreateAuctionHouseInput) {
    createAuctionHouse(auctionHouse: $auctionHouse) {
      id
      auction_house_address
      user_address
      seller_fee_point
      auction_house_fee_account
    }
  }
`;

/**
 *"listing": {
    "user_address": null,
    "seller_trade_state": null,
    "mint_address": null,
    "NFT": {
      "name": null,
      "description": null,
      "symbol": null,
      "image": null,
      "sellerFeeBasisPoints": null,
      "attributes": [
        {
          "trait_type": null,
          "value": null
        }
      ]
    },
    "price": null
  }
*/

export type TCreateListing = {
  user_address: string;
  seller_trade_state: string;
  mint_address: string;
  auction_house_address: string;
  NFT: {
    name: string;
    description: string;
    symbol: string;
    image: string;
    sellerFeeBasisPoints: string;
    attributes: {
      trait_type: string;
      value: string;
    }[];
  };
  price: string;
};

const CREATE_LISTING = gql`
  mutation Mutation($listing: CreateListingInput) {
    createListing(listing: $listing) {
      id
      user_address
      seller_trade_state
      mint_address
      cancelled
      price
      executedPrice
      name
      description
      symbol
      image
      seller_fee_basis_points
      attributes {
        trait_type
        value
      }
    }
  }
`;

const CANCEL_LISTING = gql`
  mutation CancelListing($sellerTradeState: String) {
    cancelListing(seller_trade_state: $sellerTradeState)
  }
`;

export type TCreateBid = {
  user_address: string;
  buyer_trade_state: string;
  mint_address: string;
  price: string;
};

const CREATE_OFFER = gql`
  mutation Mutation($bid: CreateBidInput) {
    createBid(bid: $bid) {
      id
      user_address
      buyer_trade_state
      mint_address
      cancelled
      price
      accepted
    }
  }
`;

const CANCEL_OFFER = gql`
  mutation Mutation($buyerTradeState: String) {
    cancelBid(buyer_trade_state: $buyerTradeState)
  }
`;

const CREATE_SALE = gql`
  mutation Mutation($saleParams: CreateSaleInput) {
    executeSale(sale_params: $saleParams)
  }
`;

export {
  CREATE_AUCTION_HOUSE,
  CREATE_LISTING,
  CANCEL_LISTING,
  CREATE_OFFER,
  CANCEL_OFFER,
  CREATE_SALE,
};
