import { useState } from 'react';
import { Button } from '../../../../_common/Button/Button';
import Input from '../../../../_common/Input/input';
import styles from './listingModal.module.scss';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import LoaderSpinner from '../../../loaderSpinner/loaderSpinner';

type Props = {
  onPressListing: (price: string) => void;
};
const ListingModal = ({ onPressListing }: Props) => {
  const solPrice = useSelector((state: RootState) => state.general.solPrice);

  const [listingPrice, setListingPrice] = useState('');

  function onSubmit() {
    //TODO Frontend validation for number
    if (listingPrice === '') {
      return toast.error('Please enter correct amount');
    }
    onPressListing(listingPrice);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <label>Sell This NFT</label>
          <div className={styles.inputWrapper}>
            <div className={styles.firstWrapper}>
              <div className={styles.leftSide}>
                <div className={styles.heading}>Set Sell Price</div>
                <div className={styles.dropDown}>SOL</div>
              </div>
              <div className={styles.rightSide}>
                {/* <div className={styles.heading}>Available: 12.342 SOL</div> */}
                <div className={styles.input}>
                  <Input
                    value={listingPrice}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setListingPrice(e?.target?.value)
                    }
                    type="number"
                    Name="name"
                    style={{ height: '39px' }}
                    placeholder="Enter Amount..."
                  />
                </div>
                <div className={styles.heading}>
                  Total amount:${(Number(listingPrice) * solPrice).toFixed(6)}
                </div>
              </div>
            </div>
            <Button
              onClick={onSubmit}
              Name="Start Listing"
              bgactive="#ffb718"
              borderHover="none"
              Coloractive="#fff"
              Colorhover="#ffb718"
              bghover="transparent"
              height="47px"
              lapheight="37px"
              type="submit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingModal;
