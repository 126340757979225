import React from 'react';
import DropDownTab from '../../DropDownTab';
import styles from './properties.module.scss';
type Props = {
  attributes?: any;
};
const Properties = (props: Props) => {
  return (
    <DropDownTab
      title="Properties"
      headerStyle={{ backgroundColor: '#ffb718' }}
      changeStyle={true}
    >
      <div className={styles.properties}>
        <div className={styles.cardWrapper}>
          {props.attributes
            ? props.attributes.map((item: any, index: any) => (
                <div key={index} className={styles.card}>
                  <label>{item.trait_type}</label>
                  <span></span>
                  <p>{item.value}</p>
                </div>
              ))
            : null}
        </div>
      </div>
    </DropDownTab>
  );
};

export default Properties;
