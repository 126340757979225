import React, { useEffect, useState } from 'react';
import Listings from './Created/created';
import Owned from './Owned/owned';
import style from './Tabs.module.scss';
function Tabs() {
  const [activeTab, setActiveTab] = useState('Owned');
  return (
    <div className={style.container}>
      <div className={style.tabs}>
        <div className={style.navWrapper}>
          <ul className={style.nav}>
            <li
              onClick={() => setActiveTab('Owned')}
              className={activeTab === 'Owned' ? style.active : ''}
            >
              Owned
            </li>
            <li
              onClick={() => setActiveTab('Listings')}
              className={activeTab === 'Listings' ? style.active : ''}
            >
              Listings
            </li>
          </ul>
        </div>
        <div className="outlet">
          {activeTab === 'Owned' && <Owned />}
          {activeTab === 'Listings' && <Listings />}
        </div>
      </div>
    </div>
  );
}

export default Tabs;
