import { gql } from '@apollo/client';

export type TListing = {
  user_address: string;
  seller_trade_state: string;
  mint_address: string;
  price: string;
  cancelled: boolean;
  executedPrice: string;
  name: string;
  description: string;
  symbol: string;
  image: string;
  auction_house_address: string;
  seller_fee_basis_points: string;
  attributes: Array<{ trait_type: string; value: string }>;
};

export type TBid = {
  accepted: boolean;
  buyer_trade_state: string;
  cancelled: boolean;
  mint_address: string;
  price: string;
  user_address: string;
};

const GET_ADMIN_LISTINGS = gql`
  query Query($userAddress: String) {
    adminListings(user_address: $userAddress) {
      id
      user_address
      seller_trade_state
      mint_address
      cancelled
      price
      executedPrice
      name
      description
      symbol
      image
      seller_fee_basis_points
      attributes {
        trait_type
        value
      }
      auction_house_address
    }
  }
`;

const LISTING_ACTIVE = gql`
  query Listing($listingParams: QueryListing) {
    listing(listing_params: $listingParams) {
      seller_trade_state
      cancelled
      price
      executedPrice
    }
  }
`;

const ALL_ACTIVE_LISTINGS = gql`
  query Query($userAddress: String) {
    listings(user_address: $userAddress) {
      id
      user_address
      seller_trade_state
      mint_address
      cancelled
      price
      executedPrice
      name
      description
      symbol
      image
      seller_fee_basis_points
      attributes {
        trait_type
        value
      }
      auction_house_address
    }
  }
`;

const QUERY_AUCTION_HOUSE = gql`
  query Query($userAddress: String) {
    auctionHouse(user_address: $userAddress) {
      id
      auction_house_address
      user_address
      seller_fee_point
      auction_house_fee_account
    }
  }
`;

const QUERY_OFFER = gql`
  query Listings($bidParams: QueryBid) {
    bid(bid_params: $bidParams) {
      id
      user_address
      buyer_trade_state
      mint_address
      cancelled
      price
      accepted
    }
  }
`;

const ALL_ACTIVE_OFFERS = gql`
  query Bids($mintAddress: String) {
    bids(mint_address: $mintAddress) {
      id
      user_address
      buyer_trade_state
      mint_address
      cancelled
      price
      accepted
    }
  }
`;

const QUERY_SALES = gql`
  query Sales($mintAddress: String) {
    sales(mint_address: $mintAddress) {
      id
      seller_address
      buyer_address
      seller_trade_state
      buyer_trade_state
      mint_address
      price
      auction_house_address
      createdAt
    }
  }
`;

export {
  GET_ADMIN_LISTINGS,
  LISTING_ACTIVE,
  QUERY_AUCTION_HOUSE,
  ALL_ACTIVE_LISTINGS,
  QUERY_OFFER,
  ALL_ACTIVE_OFFERS,
  QUERY_SALES,
};
