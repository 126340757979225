import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../../../../store/store';
import { Button } from '../../../../_common/Button/Button';
import Input from '../../../../_common/Input/input';
import styles from './offerModal.module.scss';

type Props = {
  onPressOffer: (price: string) => void;
};
const OfferModal = ({ onPressOffer }: Props) => {
  const solPrice = useSelector((state: RootState) => state.general.solPrice);
  const [offerPrice, setOfferPrice] = useState('');

  function onSubmit() {
    if (offerPrice === '') {
      return toast.error('Please enter correct amount');
    }
    onPressOffer(offerPrice);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <label>Make An Offer</label>
          <div className={styles.inputWrapper}>
            <div className={styles.firstWrapper}>
              <div className={styles.leftSide}>
                <div className={styles.heading}>Bid Amount</div>
                <div className={styles.dropDown}>
                  {/* <DropDown
                    selected={sortBy}
                    setSelected={setSortBy}
                    options={sortOptions}
                    showRightIcon={false}
                  /> */}
                  SOL
                </div>
              </div>
              <div className={styles.rightSide}>
                {/* <div className={styles.heading}>Available: 12.342 SOL</div> */}
                <div className={styles.input}>
                  <Input
                    value={offerPrice}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setOfferPrice(e?.target?.value)
                    }
                    type="text"
                    Name="name"
                    style={{ height: '39px' }}
                    placeholder="Enter Amount..."
                  />
                </div>
                <div className={styles.heading}>
                  Total amount:${(Number(offerPrice) * solPrice).toFixed(6)}
                </div>
              </div>
            </div>

            <Button
              onClick={onSubmit}
              Name="Post Offer"
              bgactive="#ffb718"
              borderHover="none"
              Coloractive="#fff"
              Colorhover="#ffb718"
              bghover="transparent"
              height="47px"
              lapheight="37px"
              type="submit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferModal;
