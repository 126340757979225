import moment from 'moment';
import React, { useState } from 'react';
import { TSale } from '../../../../Apollo/Mutations';
import DropDown from '../../../_common/Dropdown/DropDown';
import DropDownTab from '../../../_common/DropDownTab';
import BarChart from './BarChart/BarChart';
import styles from './listings.module.scss';

interface Props {
  data: any;
}
const Listings = ({ data }: Props) => {
  const chartData = data?.sales.map((sale: TSale) => ({
    name: moment(sale.createdAt).format('MMMM Do YYYY, hh:mm a'),
    price: Number(sale.price),
  }));

  const averagePrice =
    chartData?.reduce((a: any, b: any) => a + b.price, 0) / chartData?.length;
  //Parse data for chart
  const [filterBy, setFilterBy] = useState('All Time');
  const filterOptions = ['Top'];
  return (
    <div className={styles.dropDownWrapper}>
      <DropDownTab
        title="Listings"
        headerStyle={{ backgroundColor: '#ffb718' }}
      >
        <div className={styles.graphDropDown}>
          <div className={styles.dropDown}>
            <DropDown
              selected={filterBy}
              setSelected={setFilterBy}
              options={filterOptions}
              showRightIcon={false}
            />
          </div>

          <div className={styles.textArea}>
            <label>Absolute Avg Price</label>
            <p>
              {' '}
              <img
                src={'https://d3bfm8su4pz02o.cloudfront.net/icons/NFTIcon.svg'}
                alt=""
              />
              {averagePrice} SOL
            </p>
          </div>
        </div>
        <BarChart chartData={chartData} />
      </DropDownTab>
    </div>
  );
};

export default Listings;
