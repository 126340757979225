import { createSlice } from '@reduxjs/toolkit';

type GeneratState = {
  solPrice: number;
  isAdmin: boolean;
};

const generalSlice = createSlice({
  name: 'general',
  initialState: { solPrice: 0, isAdmin: false } as GeneratState,
  reducers: {
    setSolPrice: (state, action) => {
      state.solPrice = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
  },
});

export const { setSolPrice, setIsAdmin } = generalSlice.actions;

export default generalSlice.reducer;
