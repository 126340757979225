import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { QUERY_SALES } from '../../../Apollo/Queries';
import { calculateCreatorEarning } from '../../../services/nft.service';
import { RootState } from '../../../store/store';
import Activity from './activityComponet';
import Description from './description';
import ImageComponent from './imageComponent';
import Listings from './listings';
import styles from './NftDetails.module.scss';
import Offers from './offers';
import PriceHistory from './priceHistory';
import Prices from './prices';
import Properties from './properties';
const NftDetails = () => {
  const location = useLocation();
  const isAdmin = useSelector((state: RootState) => state.general.isAdmin);

  const routedFromListings = useMemo(
    () => location.state?.routedFromListings,
    []
  );

  const mint_address = useMemo(
    () =>
      location?.state.mint_address
        ? location?.state.mint_address
        : location?.state?.mintAddress.toString(),
    []
  );
  const { data: historyData } = useQuery(QUERY_SALES, {
    variables: { mintAddress: mint_address },
    fetchPolicy: 'network-only',
  });

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.mainWrapper}>
          <div className={styles.topWrapper}>
            <div className={styles.left}>
              <div className={styles.orderWrapper}>
                <ImageComponent imageURI={location?.state?.image} />
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.orderWrapper}>
                <Prices
                  routedFromListings={routedFromListings}
                  nftItem={location?.state}
                  name={location?.state?.name}
                  showSale={false}
                />
              </div>
              {routedFromListings && isAdmin && (
                <div className={styles.orderWrapper}>
                  <Offers
                    selling_price={location?.state?.price}
                    seller_trade_state={location?.state?.seller_trade_state}
                    auction_house_address={
                      location?.state?.auction_house_address
                    }
                    mint_address={
                      location?.state.mint_address
                        ? location?.state.mint_address
                        : location?.state?.mintAddress.toString()
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.middleWrapper}>
            <div className={styles.left}>
              <div className={styles.orderWrapper}>
                <Description
                  description={location?.state?.description}
                  tokenID={
                    location?.state?.mintAddress
                      ? location?.state?.mintAddress.toString()
                      : location?.state?.mint_address
                  }
                  creatorEarnings={calculateCreatorEarning(
                    location?.state?.sellerFeeBasisPoints ||
                      location?.state?.sellerFeeBasisPoints == 0
                      ? location?.state?.sellerFeeBasisPoints
                      : location?.state?.seller_fee_basis_points
                  )}
                />
              </div>
              <div className={styles.orderWrapper}>
                <Properties attributes={location?.state?.attributes} />
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.orderWrapper}>
                <Listings data={historyData} />
              </div>
              <div className={styles.orderWrapper}>
                <PriceHistory data={historyData} />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.bottomWrapper}>
          <Activity mint_address={mint_address} />
        </div>
      </div>
    </div>
  );
};

export default NftDetails;
