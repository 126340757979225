export function parseObjectForListingMutation(params: {
  user_address: string;
  price: string;
  seller_trade_state: string;
  nftItem: any;
  ahAddress: string;
}) {
  return {
    user_address: params.user_address,
    seller_trade_state: params.seller_trade_state,
    auction_house_address: params.ahAddress,
    mint_address: params.nftItem?.mint_address
      ? params.nftItem?.mint_address
      : params.nftItem?.mintAddress.toString(),
    NFT: {
      name: params.nftItem.name,
      description: params.nftItem.description,
      symbol: params.nftItem.symbol,
      image: params.nftItem.image,
      sellerFeeBasisPoints: String(params.nftItem.sellerFeeBasisPoints),
      attributes: params.nftItem.attributes,
    },
    price: params.price,
  };
}
