import React from "react";
import styles from "./Switch.module.scss";
interface prop {
  onChange?: any;
  checked?: boolean;
}
const Switch = (Prop: prop) => {
  const { onChange, checked } = Prop;
  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        onChange={onChange}
        defaultChecked={checked}
        style={{
          cursor: "pointer",
        }}
      />
      <span className={`${styles.round} ${styles.slider}`} />
    </label>
  );
};

export default Switch;
