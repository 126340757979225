import React from 'react'
import styles from "./image.module.scss"
type Props = {
    imageURI?: string;
};
const ImageComponent = (props: Props) => {

    return (
        <div className={styles.imgWrapper}>
            <img src={props.imageURI} alt="" />
            <div className={styles.imgIcons}>
                {/* <div className={styles.leftWrapper}>
              <div className={styles.icon}>
                <RiHeart2Fill />
              </div>
              1,12
            </div> */}
                <div className={styles.rightWrapper}>
                    <img
                        src={"https://d3bfm8su4pz02o.cloudfront.net/icons/NFTIcon.svg"}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}

export default ImageComponent