import { useConnection } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import DropDownTab from '../../../_common/DropDownTab';
import styles from './activity.module.scss';
import { PublicKey } from '@solana/web3.js';
import moment from 'moment';

interface Props {
  mint_address: string;
}

const Activity = ({ mint_address }: Props) => {
  const { connection } = useConnection();
  const [activities, setActivities] = useState<any>([]);

  const getMinttx = async () => {
    let mintAd = new PublicKey(mint_address);
    let txns = await connection.getSignaturesForAddress(mintAd, { limit: 10 });
    let parsedTxs = [];
    let txnList = txns.map((transaction) => transaction.signature);

    for (let sig of txnList) {
      let txnDetails = await connection.getParsedTransaction(sig);

      let tx: any = txnDetails?.transaction;
      let firstInstruction: any =
        txnDetails?.transaction.message.instructions[0];
      parsedTxs.push({
        event: firstInstruction?.parsed?.type || 'unknown',
        address: tx?.message?.accountKeys[0]?.pubkey?.toString(),
        time: moment(tx?.txnDetails?.blockTime).format('DD MMM YYYY hh:mm a'),
      });
    }
    setActivities(parsedTxs);
  };

  useEffect(() => {
    getMinttx();
  }, []);

  console.log(activities);

  return (
    <DropDownTab
      title="Item Activity"
      headerStyle={{ backgroundColor: '#ffb718' }}
    >
      <div className={styles.dropDownWrapper}>
        <table cellSpacing="0">
          <thead>
            <tr>
              <th>Event</th>
              <th>Address</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {activities?.map((activity: any, index: number) => (
              <tr>
                <td>{activity?.event}</td>
                <td>{activity?.address}</td>
                <td>{activity?.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DropDownTab>
  );
};

export default Activity;
