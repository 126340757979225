import { useSelector } from 'react-redux';
import { TSale } from '../../../../Apollo/Mutations';
import { ellipsisText } from '../../../../services/string.helper';
import { RootState } from '../../../../store/store';
import DropDownTab from '../../../_common/DropDownTab';
import styles from './priceHistory.module.scss';
import moment from 'moment';

interface Props {
  data: any;
}
const PriceHistory = ({ data }: Props) => {
  const solPrice = useSelector((state: RootState) => state.general.solPrice);

  return (
    <div className={styles.dropDownWrapper}>
      <DropDownTab
        title="Price History"
        headerStyle={{ backgroundColor: '#ffb718' }}
      >
        <table cellSpacing="0">
          <thead>
            <tr>
              <th>Price</th>
              <th>USD Price</th>
              <th>Buyer</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {Boolean(data?.sales) &&
              data?.sales.map((sale: TSale, index: any) => (
                <tr key={index}>
                  <td>
                    <img
                      src={
                        'https://d3bfm8su4pz02o.cloudfront.net/icons/NFTIcon.svg'
                      }
                      alt=""
                    />{' '}
                    {sale.price}
                  </td>
                  <td>${(Number(sale.price) * solPrice).toFixed(6)}</td>
                  <td>{ellipsisText(sale.buyer_address)}</td>
                  <td>
                    {moment(sale.createdAt).format('MMMM Do YYYY, hh:mm a')}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </DropDownTab>
    </div>
  );
};

export default PriceHistory;
