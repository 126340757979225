import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Footer from './Components/_common/Footer/Footer';
import Navbar from './Components/_common/NavBar/NavBar';
import './index.css';
import Create from './Pages/Create/Create';
import Home from './Pages/Home/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './Components/_common/scrollToTop';
import Explore from './Pages/Explore/Explore';
import Profile from './Pages/Profile/Profile';
import NftDetails from './Components/_common/nftDetails';
import Settings from './Pages/settings';
import { setIsAdmin, setSolPrice } from './store/generalSlice';
import { getSolanaUSDPrice } from './services/api.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';

let currentKey = '';

function App() {
  const { wallet } = useWallet();
  const isAdmin = useSelector((state: RootState) => state.general.isAdmin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (window?.solana != undefined) {
      window.solana.on('disconnect', () => {
        console.log('disconnected');
        navigate('/');
        dispatch(setIsAdmin(false));
      });
      window.solana.on('connect', () => {});
    }
  }, []);

  useEffect(() => {
    if (wallet?.adapter.connected) {
      getSolanaUSDPrice()
        .then((res) => {
          dispatch(setSolPrice(Number(res)));
        })
        .catch((err) => console.log('Error while getting sol price:', err));
      currentKey = wallet?.adapter.publicKey?.toString()!;
      if (
        wallet?.adapter.publicKey?.toString() ===
        process.env.REACT_APP_ADMIN_WALLET_ADDRESS
      ) {
        dispatch(setIsAdmin(true));
      } else {
        dispatch(setIsAdmin(false));
      }
    }
  }, [wallet?.adapter.connected]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        closeOnClick={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/create" element={<Create />} />
        <Route path="/explore" element={<Explore />} />
        {isAdmin && <Route path="/profile" element={<Profile />} />}
        <Route path="/nft-details" element={<NftDetails />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
