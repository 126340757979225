import style from './barChart.module.scss';
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface prop {
  defaultColor?: any;
  chartData?: any;
}
const BarChart = (Props: prop) => {
  const { chartData, defaultColor } = Props;
  return (
    <div className={style.container}>
      {chartData ? (
        <ResponsiveContainer width="100%" height="90%">
          <AreaChart
            width={1000}
            height={300}
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: -30,
            }}
          >
            <XAxis dataKey="name" tick={false} axisLine={false} />

            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="80%" stopColor="#D26BF5" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#D26BF5" stopOpacity={0.1} />
              </linearGradient>

              <linearGradient id="colorUvW" x1="0" y1="0" x2="0" y2="1">
                <stop offset="50%" stopColor="#ffb718" stopOpacity={1} />
                <stop offset="95%" stopColor="#ffb718" stopOpacity={0} />
              </linearGradient>
            </defs>

            <Tooltip />
            <Area
              className={style.wrapper}
              type="monotone"
              dataKey="price"
              stackId="1"
              stroke={`${defaultColor ? '#D26BF5 ' : '#ffb718'}`}
              fill={`url(#${defaultColor ? 'colorUv ' : 'colorUvW'})`}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <h5 style={{ textAlign: 'center' }}>No Historical data available</h5>
      )}
    </div>
  );
};

export default BarChart;
