import NftCard from '../../../_common/NftCards/nftCards/nftCard';
import styles from './created.module.scss';
import { ColorRing } from 'react-loader-spinner';
import { useMetaplex } from '../../../../hooks/useMetaplex';
import NoDataFound from '../../../_common/noDataGif/noDataFound';
import { useQuery } from '@apollo/client';
import { GET_ADMIN_LISTINGS } from '../../../../Apollo/Queries';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';

const Listings = () => {
  const { publicKey } = useWallet();
  const { loading, error, data } = useQuery(GET_ADMIN_LISTINGS, {
    variables: {
      userAddress: publicKey?.toBase58(),
    },
    fetchPolicy: 'network-only',
  });
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {loading ? (
            <div className={styles.loader}>
              <ColorRing
                visible={true}
                height="80"
                width="100"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={[
                  'rgb(255, 183, 24)',
                  'rgb(255, 183, 24)',
                  'rgb(255, 183, 24)',
                  'rgb(255, 183, 24)',
                  'rgb(255, 183, 24)',
                ]}
              />
            </div>
          ) : data?.adminListings && data?.adminListings.length === 0 ? (
            <NoDataFound />
          ) : (
            <div className={styles.cardWrapper}>
              {data?.adminListings &&
                data?.adminListings.map((item: any, index: any) => (
                  <NftCard
                    onClick={() =>
                      navigate('/nft-details', {
                        state: { ...item, routedFromListings: true },
                      })
                    }
                    item={item}
                    key={index}
                    img={item.image}
                    heading={item.name}
                    subHeading={item.description}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Listings;
