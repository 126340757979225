import { useLazyQuery, useQuery } from '@apollo/client';
import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ALL_ACTIVE_LISTINGS, LISTING_ACTIVE } from '../../../Apollo/Queries';
import CategoryCard from '../../_common/NftCards/categoryCard/categoryCard';
import styles from './CategoryOne.module.scss';
// let data = [
//   {
//     img: "https://d3bfm8su4pz02o.cloudfront.net/images/CatalyticConverter.jpeg",
//     heading: "Catalytic Converter",
//     subHeading: "-",
//     Price: "20000",
//     lastSale: "2055",
//   },
//   {
//     img: "https://d3bfm8su4pz02o.cloudfront.net/images/coupling.jpeg",
//     heading: "Quick Couplings",
//     subHeading: "-",
//     Price: "20000",
//     lastSale: "2055",
//   },
//   {
//     img: "https://d3bfm8su4pz02o.cloudfront.net/images/GoldKrugerrandCoin.jpeg",
//     heading: "Gold Krugerrand Coin",
//     subHeading: "-",
//     Price: "20000",
//     lastSale: "2055",
//   },
//   {
//     img: "https://d3bfm8su4pz02o.cloudfront.net/images/Hammond.png",
//     heading: "HAMMOND",
//     subHeading: "-",
//     Price: "20000",
//     lastSale: "2055",
//   },
// ];

const CategoryOne = () => {
  const navigation = useNavigate();
  const { wallet } = useWallet();

  const { data, error, loading } = useQuery(ALL_ACTIVE_LISTINGS, {
    variables: {
      userAddress: process.env.REACT_APP_ADMIN_WALLET_ADDRESS,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <label></label>
            <p></p>
          </div>
          <div className={styles.cardWrapper}>
            {Boolean(data?.listings) &&
              data?.listings.map((item: any, index: any) => (
                <div className={styles.card} key={index}>
                  <CategoryCard
                    listing={item}
                    onClick={() =>
                      navigation('/nft-details', {
                        state: { ...item, routedFromListings: true },
                      })
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryOne;
