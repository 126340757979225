import React from "react";
import Input from "../../Components/_common/Input/input";
import styles from "./settings.module.scss";
import { BsTwitter } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { Button } from "../../Components/_common/Button/Button";
import ToggleCards from "./toggleComp";

const Settings = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.COntent}>
            <div className={styles.heading}>Profile</div>
            <div className={styles.InputWRapper}>
              <label>UserName</label>
              <Input
                type="text"
                Name="name"
                placeholder=""
                style={{ height: "45px", border: "" }}
              />
            </div>
            <div className={styles.InputWRapper}>
              <label>Wallet Address</label>
              <Input
                type="text"
                Name="name"
                placeholder=""
                style={{ height: "45px", border: "" }}
              />
            </div>
            <div className={styles.InputWRapper}>
              <label>BIo</label>
              <Input
                type="text"
                Name="name"
                placeholder=""
                style={{ height: "45px", border: "" }}
              />
            </div>
            <div className={styles.InputWRapper}>
              <label>Email Address</label>
              <Input
                type="text"
                Name="name"
                placeholder=""
                style={{ height: "45px", border: "" }}
              />
            </div>
            <div className={styles.connectionWrapper}>
              <div className={styles.text}>Social Connections</div>
              <div className={styles.subText}>
                Help collectors verify your account by connecting social account
              </div>
              <div className={styles.btnWrapper}>
                <div className={styles.left}>
                  <div className={styles.icon}>
                    <BsTwitter />
                  </div>
                  <Button
                    Name="Connect"
                    bgactive="transparent"
                    customStyle={{
                      height: "35px",
                      padding: "0 0.5rem",
                      fontSize: "17px",
                    }}
                  />
                </div>
                <div className={styles.right}>
                  <div className={styles.icon}>
                    <FaLinkedinIn />
                  </div>
                  <Button
                    Name="Connect"
                    bgactive="transparent"
                    customStyle={{
                      height: "35px",
                      padding: "0 0.5rem",
                      fontSize: "17px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.links}>
              <div className={styles.InputWRapper}>
                <label>Links</label>
                <Input
                  type="text"
                  Name="name"
                  placeholder=""
                  style={{ height: "45px", border: "" }}
                />
              </div>
            </div>
            <hr />
          </div>
          <ToggleCards />
        </div>
      </div>
    </>
  );
};

export default Settings;
