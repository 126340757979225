import React from 'react';
import { useSelector } from 'react-redux';
import { TListing } from '../../../../Apollo/Queries';
import { RootState } from '../../../../store/store';
import styles from './categoryCard.module.scss';
interface Props {
  listing: TListing;
  onClick: () => void;
}
const CategoryCard = ({ listing, onClick }: Props) => {
  const isAdmin = useSelector((state: RootState) => state.general.isAdmin);

  return (
    <>
      <div className={styles.cardWrapper}>
        <img src={listing.image} alt="" />
        <div className={styles.textWrapper}>
          <label>{listing.name}</label>
          <p>{listing.description}</p>
        </div>
        <div className={styles.priceWrapper}>
          <div className={styles.heading}>Price:</div>
          <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
              <img
                src={'https://d3bfm8su4pz02o.cloudfront.net/icons/NFTIcon.svg'}
                alt=""
              />
              <label>{listing.price ? listing.price : '-'}</label>
            </div>
            {/* <div className={styles.rightWrapper}>
              Last Sale:{' '}
              <img
                src={'https://d3bfm8su4pz02o.cloudfront.net/icons/NFTIcon.svg'}
                alt=""
              />{' '}
              <label>{lastSale ? lastSale : '-'}</label>
            </div> */}
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <button onClick={onClick}>
            {isAdmin ? 'View Listing' : 'Make Offer'}
          </button>
        </div>
      </div>
    </>
  );
};

export default CategoryCard;
