import { useMutation, useQuery } from '@apollo/client';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  CANCEL_OFFER,
  CREATE_OFFER,
  TCreateBid,
} from '../../../../Apollo/Mutations';
import { QUERY_OFFER, TListing } from '../../../../Apollo/Queries';
import { useMetaplex } from '../../../../hooks/useMetaplex';
import {
  cancelMXbid,
  createMXBid,
} from '../../../../services/metaplex.service';

export function useOffer(nftItem: TListing, toggleOfferModal: () => void) {
  const { publicKey, sendTransaction } = useWallet();
  const { metaplex } = useMetaplex();
  const { connection } = useConnection();

  const [loading, setLoading] = useState(false);

  const { data, error, refetch } = useQuery(QUERY_OFFER, {
    variables: {
      bidParams: {
        mint_address: nftItem.mint_address,
        user_address: publicKey?.toBase58(),
      },
    },
    fetchPolicy: 'network-only',
  });
  const [saveOffer] = useMutation<any, { bid: TCreateBid }>(CREATE_OFFER);
  const [removeOffer] = useMutation<any, { buyerTradeState: string }>(
    CANCEL_OFFER
  );
  const onCreateOffer = async (offer_price: string) => {
    try {
      toast.success('Creating Offer...');
      if (!publicKey) throw new WalletNotConnectedError();
      setLoading(true);
      let bid = await createMXBid(
        metaplex!,
        offer_price,
        nftItem.auction_house_address,
        nftItem.mint_address
      );
      let { data } = await saveOffer({
        variables: {
          bid,
        },
      });
      toast.success('Offer Created!');
      toggleOfferModal();
      await refetch();
      setLoading(false);
    } catch (error: any) {
      toggleOfferModal();
      console.log('Error occured while creating listing: ', error);
      toast.error('Error occured while creating listing:' + error.message);
      setLoading(false);
    }
  };

  const onCancelOffer = async () => {
    let buyer_trade_state = data.bid.buyer_trade_state;
    try {
      toast.success('Cancelling Offer...');
      if (!publicKey) throw new WalletNotConnectedError();
      setLoading(true);

      await cancelMXbid(
        metaplex!,
        buyer_trade_state,
        nftItem.auction_house_address
      );
      await removeOffer({
        variables: {
          buyerTradeState: buyer_trade_state,
        },
      });
      await refetch();
      setLoading(false);
    } catch (error: any) {
      console.log('Error occured while creating listing: ', error);
      toast.error('Error occured while creating listing:' + error.message);
      setLoading(false);
    }
  };

  return [loading, onCreateOffer, onCancelOffer, data?.bid] as const; // infers [boolean, typeof load] instead of (boolean | typeof load)[]
}
