import { client } from '..';
import { gql } from '@apollo/client';
import { QUERY_AUCTION_HOUSE } from '../Apollo/Queries';
export const getSolanaUSDPrice = async () => {
  const response = await fetch(
    'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
  );
  const data = await response.json();
  return data.solana.usd;
};

export const checkIfAuctionHouseExists = async (userAddress: string) => {
  let res = await client
    .query({
      query: QUERY_AUCTION_HOUSE,
      variables: {
        userAddress,
      },
      fetchPolicy: 'network-only',
    })
    .catch((err) => {
      console.log('Error occured while fetching auction house: ', err);
      throw err;
    });
  if (res.data.auctionHouse) {
    return res.data.auctionHouse;
  } else return false;
};
