import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { ALL_ACTIVE_OFFERS, TBid } from '../../../../Apollo/Queries';
import { RootState } from '../../../../store/store';
import DropDownTab from '../../../_common/DropDownTab';
import styles from './offers.module.scss';
import { ellipsisText } from '../../../../services/string.helper';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useWallet } from '@solana/wallet-adapter-react';
import { useMetaplex } from '../../../../hooks/useMetaplex';
import { useNavigate } from 'react-router-dom';
import { executeSale } from '../../../../services/metaplex.service';
import { CREATE_SALE, TCreateSale } from '../../../../Apollo/Mutations';
import LoaderSpinner from '../../loaderSpinner/loaderSpinner';
interface Props {
  mint_address: string;
  auction_house_address: string;
  seller_trade_state: string;
  selling_price: string;
}

const Offres = ({
  mint_address,
  auction_house_address,
  seller_trade_state,
  selling_price,
}: Props) => {
  const { data: activeOffers, error } = useQuery(ALL_ACTIVE_OFFERS, {
    variables: {
      mintAddress: mint_address,
    },
    fetchPolicy: 'network-only',
  });
  const solPrice = useSelector((state: RootState) => state.general.solPrice);
  const [loading, setLoading] = useState(false);
  const { publicKey } = useWallet();
  const { metaplex } = useMetaplex();
  const navigate = useNavigate();

  const [saveSale] = useMutation<any, { saleParams: TCreateSale }>(CREATE_SALE);

  const onAcceptOffer = async (bid: TBid) => {
    try {
      toast.success('Accept Offer...');
      if (!publicKey) throw new WalletNotConnectedError();
      setLoading(true);
      //Full screen loader required
      let mxRes = await executeSale(
        metaplex!,
        bid.buyer_trade_state,
        seller_trade_state,
        auction_house_address,
        bid.price,
        mint_address,
        selling_price
      );
      let saleParams: TCreateSale = {
        ...mxRes,
        price: bid.price,
        buyer_address: bid.user_address,
        mint_address: mint_address,
      };
      await saveSale({ variables: { saleParams } });
      //Update sale in DB COME BACK HERE
      toast.success('Offer Accepted');
      setLoading(false);
      navigate('/profile');
    } catch (error: any) {
      console.log('Error occured while creating listing: ', error);
      toast.error('Error occured while creating listing:' + error.message);
      setLoading(false);
    }
  };

  if (error) return <h3>Something went wrong!</h3>;
  return (
    <div className={styles.dropDownWrapper}>
      <DropDownTab title="Offers" headerStyle={{ backgroundColor: '#ffb718' }}>
        <table cellSpacing="0">
          <tr>
            <th>Price</th>
            <th>USD Price</th>
            <th>From</th>
            <th></th>
          </tr>
          {activeOffers?.bids &&
            activeOffers?.bids.map((_: TBid, index: any) => (
              <tr key={index}>
                <td>
                  <img
                    src={
                      'https://d3bfm8su4pz02o.cloudfront.net/icons/NFTIcon.svg'
                    }
                    alt=""
                  />{' '}
                  {_.price}
                </td>
                <td>${(Number(_.price) * solPrice).toFixed(6)}</td>
                <td>{ellipsisText(_.user_address)}</td>
                <td>
                  {loading ? (
                    <LoaderSpinner />
                  ) : (
                    <button onClick={() => onAcceptOffer(_)}>Accept</button>
                  )}
                </td>
              </tr>
            ))}
        </table>
      </DropDownTab>
    </div>
  );
};

export default Offres;
