import React, { useState } from "react";
import Switch from "../../../Components/_common/Switch";
import styles from "./index.module.scss";

const ToggleCards = () => {
  const [active, setActive] = useState(false);
  const switchToggle = (e: any) => {
    setActive(e.target.checked);
  };
  const data = [
    {
      id: 1,
      text: "Successful bids",
      subText: "When your bid was successful and the NFT is in your wallet",
    },
    {
      id: 2,
      text: "Bids & Outbids",
      subText: "When someone bids on one of your items or outbids yours bids",
    },
    {
      id: 1,
      text: "Expired bids",
      subText:
        "When your bid expires or gets deactivated because of insufficient funds",
    },
  ];
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.heading}>Settings</div>
        <div className={styles.cardWrapper}>
          {data?.map((item) => {
            return (
              <>
                <div className={styles.Card} key={item.id}>
                  <div className={styles.left}>
                    <div className={styles.text}>{item.text}</div>
                    <div className={styles.subText}>{item.subText}</div>
                  </div>
                  <div className={styles.right}>
                    <Switch onChange={(e: any) => switchToggle(e)} />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ToggleCards;
