import { useMutation, useQuery } from '@apollo/client';
import { Metaplex } from '@metaplex-foundation/js';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  TCreateAuctionHouse,
  CREATE_AUCTION_HOUSE,
  CREATE_LISTING,
  TCreateListing,
  CANCEL_LISTING,
} from '../../../../Apollo/Mutations';
import { LISTING_ACTIVE } from '../../../../Apollo/Queries';
import { useMetaplex } from '../../../../hooks/useMetaplex';
import { checkIfAuctionHouseExists } from '../../../../services/api.service';
import {
  cancelMXListing,
  createMXAuctionHouse,
  createMXListing,
} from '../../../../services/metaplex.service';
import { parseObjectForListingMutation } from '../../../../services/object.service';

export function useListing(
  nftItem: any,
  user_address: string,
  toggleListingModal: () => void
) {
  const { publicKey, sendTransaction } = useWallet();
  const { metaplex } = useMetaplex();
  const { connection } = useConnection();

  const mint_address = useMemo(
    () =>
      nftItem?.mint_address
        ? nftItem?.mint_address
        : nftItem?.mintAddress.toString(),
    []
  );

  const [loading, setLoading] = useState(false);

  const { data, error, refetch } = useQuery(LISTING_ACTIVE, {
    variables: {
      listingParams: {
        mint_address,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [createAuctionHouse] = useMutation<
    any,
    { auctionHouse: TCreateAuctionHouse }
  >(CREATE_AUCTION_HOUSE);

  const [saveListing] = useMutation<any, { listing: TCreateListing }>(
    CREATE_LISTING
  );

  const [gqlCancelListing] = useMutation<any, { sellerTradeState: string }>(
    CANCEL_LISTING
  );

  const onCreateAuctionHouse = async (
    metaplex: Metaplex,
    connection: Connection,
    sendTransaction: any
  ) => {
    try {
      await onCreateAuctionHouse(metaplex!, connection, sendTransaction);
      let ah = await createMXAuctionHouse(
        metaplex!,
        connection,
        sendTransaction
      );
      //Storing Auction House in DB
      let { data, errors } = await createAuctionHouse({
        variables: {
          auctionHouse: ah,
        },
      });
      return { ahAddress: ah.auction_house_address };
    } catch (error) {
      console.log('onCreateAuctionHouse error', error);
      throw error;
    }
  };

  const createListing = async (args: {
    price: string;
    ahAddress: string;
    mint_address: string;
    user_address: string;
  }) => {
    console.log('ARGS for createListing', args);
    try {
      console.log('Creating MX listings.....');
      let { seller_trade_state } = await createMXListing(
        metaplex!,
        args.price,
        args.ahAddress,
        args.mint_address
      );
      let parseNFTMutationObj = parseObjectForListingMutation({
        nftItem,
        user_address: args.user_address,
        seller_trade_state: seller_trade_state,
        price: args.price,
        ahAddress: args.ahAddress,
      });
      console.log('Parsed Object check:', parseNFTMutationObj);
      //Store listing in DB
      console.log('Saving listing in DB.....');
      let { data, errors } = await saveListing({
        variables: {
          listing: parseNFTMutationObj,
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const onCancelListing = async (seller_trade_state: string) => {
    console.log('Seller trade check:', seller_trade_state);
    try {
      toast.success('Cancelling Listing...');
      if (!publicKey) throw new WalletNotConnectedError();
      setLoading(true);
      let ahRes = await checkIfAuctionHouseExists(publicKey!.toBase58());
      //Cancel mx listing
      await cancelMXListing(
        metaplex!,
        ahRes.auction_house_address,
        seller_trade_state
      );
      //Cancel gql listing
      await gqlCancelListing({
        variables: {
          sellerTradeState: seller_trade_state,
        },
      });
      await refetch();
      toast.success('Listing Cancelled');
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log('Error occured while creating listing: ', error);
      toast.error('Error occured while creating listing:' + error.message);
    }
  };

  const onCreateListing = async (listing_price: string) => {
    try {
      toast.success('Initializing Listing...');
      if (!publicKey) throw new WalletNotConnectedError();
      setLoading(true);
      let ahRes = await checkIfAuctionHouseExists(publicKey!.toBase58());
      //Save to ah data to state
      if (!ahRes) {
        let { ahAddress } = await onCreateAuctionHouse(
          metaplex!,
          connection,
          sendTransaction
        );
        toast.success('Creating Listing...');
        await createListing({
          price: listing_price,
          ahAddress,
          mint_address: mint_address,
          user_address: publicKey!.toBase58(),
        });
      } else {
        console.log('AH aleady exists----Creating Listing...');
        await createListing({
          price: listing_price,
          ahAddress: ahRes.auction_house_address,
          mint_address: mint_address,
          user_address: publicKey!.toBase58(),
        });
      }
      console.log('Refetching listings.....');
      await refetch();
      toast.success('Listing created successfully');
      toggleListingModal();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log('Error occured while creating listing: ', error);
      toast.error('Error occured while creating listing:' + error.message);
      toggleListingModal();
    }
  };

  return [onCreateListing, loading, data, onCancelListing] as const; // infers [boolean, typeof load] instead of (boolean | typeof load)[]
}
